$justify-content-values: 'start', 'center', 'end', 'space-between',
'space-around', 'space-evenly', 'stretch';
$align-items-values: 'start', 'center', 'end', 'stretch', 'baseline';

@layer edwin {
  /* TODO: other flex options? */
  .flex {
    display: flex;

    &.row {
      flex-direction: row;
    }
    &.column {
      flex-direction: column;
    }

    // alignment
    @each $justify-content-value in $justify-content-values {
      &.justify-content-#{$justify-content-value} {
        justify-content: #{$justify-content-value};
      }
    }
    @each $align-items-value in $align-items-values {
      &.align-items-#{$align-items-value} {
        align-items: #{$align-items-value};
      }
    }

    @for $i from 0 through 30 {
      &.gap-#{$i} {
        gap: #{$i * 4}px;
      }
    }
  }
}